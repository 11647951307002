var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dialog-edit2',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.id)?_c('small',[_vm._v(" ("+_vm._s(_vm.id)+")")]):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? _vm.m.title + ` №${_vm.data.code_doc}` : "Новый документ '" + _vm.m.title + "'")+" ["+_vm._s(_vm.statusCurrent.text || "")+"] "),_c('br'),_vm._v(" "+_vm._s(_vm.config.name)+" ")]},proxy:true},{key:"actions",fn:function(){return [(!_vm.loading && _vm.isStatus)?_c('a-btn-status',{attrs:{"items":_vm.statusItems,"disabled":!_vm.canStatus},on:{"click":function($event){return _vm.changeStatus($event)}}}):_vm._e(),(!_vm.loading && _vm.data.status == 2)?_c('v-btn',{staticClass:"mx-1",attrs:{"href":_vm.getPreview(),"target":"_blank","dark":"","color":"info","title":"Печать"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fas fa-print ")])],1):_vm._e(),(_vm.data.status)?_c('a-btn-acc-record',{attrs:{"id":_vm.id,"name":_vm.m.accDocName}}):_vm._e(),_c('v-spacer'),(_vm.data.import_data)?_c('a-btn-import-data',{attrs:{"value":_vm.data.import_data,"model":{ name: 'import_data', title: 'Данные импорта' }}}):_vm._e(),(!_vm.loading && _vm.id && _vm.isDelete)?_c('a-btn-delete',{on:{"click":function($event){_vm.removeDialogShow = true}}}):_vm._e(),(!_vm.loading && _vm.isEdit)?_c('a-btn-save',{attrs:{"color":_vm.isChanged ? 'green' : 'primary'},on:{"click":function($event){return _vm.submit()}}}):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Закрыть")])]},proxy:true}])},[(_vm.loading)?_c('a-loader'):_c('v-row',[(
          !_vm.id &&
          !_vm.document.id &&
          _vm.model.find((m) => {
            return m.name == 'parent_id';
          })
        )?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{on:{"click":function($event){_vm.showSelectDialog = true}}},[_vm._v(" Выбрать документ ")])],1):_vm._e(),_vm._l((_vm.modelForm),function(el,i){return (_vm.modelForm)?_c('v-col',{key:i,staticClass:"py-0",attrs:{"cols":"12","sm":el.size}},[(el.name == 'balance')?_c('a-form-view',{attrs:{"value":_vm.balance,"model":[
            { name: 'debit', title: 'Всего к оплате по документу', type: 'number' },
            { name: 'credit', title: 'Всего выплачено по документу', type: 'number' },
            { name: 'saldo', title: 'Не выплачено по документу', value: -_vm.balance.credit + _vm.balance.debit, type: 'number' },
          ],"config":{ dense: true }},on:{"click":function($event){return _vm.getBalance()}}}):_vm._l((el.name.split('+')),function(name){return _c('div',{key:name},[_c('a-form-model',{ref:'field_' + name,refInFor:true,attrs:{"model":_vm.getFormModel([name]),"errors":_vm.errors,"config":{ dense: true, readonly: _vm.readonly }},on:{"validate":function($event){return _vm.validate($event)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)})],2):_vm._e()})],2)],1),(_vm.showSelectDialog)?_c('select-dialog',{attrs:{"config":_vm.config},on:{"select":function($event){return _vm.onSelectDocument($event)}},model:{value:(_vm.showSelectDialog),callback:function ($$v) {_vm.showSelectDialog=$$v},expression:"showSelectDialog"}}):_vm._e(),_c('remove-dialog',{on:{"remove":function($event){return _vm.remove(_vm.api, _vm.id)}},model:{value:(_vm.removeDialogShow),callback:function ($$v) {_vm.removeDialogShow=$$v},expression:"removeDialogShow"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }